import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './../store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}


const routes = [{
  path: '/',
  name: 'landing',
  component: () =>
    import( /* webpackChunkName:"landing"*/ './../views/Landing.vue'),
  /* meta: {
    redirectToSearchIfLoggedIn: true
  } */
},
{
  path: '/register',
  name: 'register',
  component: () =>
    import( /* webpackChunkName:"register"*/ './../views/Register.vue'),
},
{
  path: '/expired',
  component: () =>
    import( /* webpackChunkName:"expired"*/ './../views/ExpiredAd.vue'),
  name: 'expired',
},
{
  path: "/expired-token",
  component: () => import( /* webpackChunkName:"expired-token"*/ "./../views/ExpiredToken.vue"),
  name: "expired-token",
},
{
  path: '/generation-de-leads',
  component: () => import( /* webpackChunkName:"generation-de-leads"*/ './../views/Naas.vue'),
  name: 'naas',
},
{
  path: '/thanks',
  component: () =>
    import(
      /* webpackChunkName:"thanks-signup"*/
      './../views/ThanksSignup.vue'
    ),
  name: 'thanks-signup',
},
{
  path: '/thanks-retarget',
  component: () =>
    import(
      /* webpackChunkName:"thanks-retarget"*/
      './../views/ThanksRetarget.vue'
    ),
  name: 'thanks-retarget',
},
{
  path: '/reset-success',
  component: () =>
    import(
      /* webpackChunkName:"reset-success"*/
      './../views/ThanksReset.vue'
    ),
  name: 'reset-success',
},
{
  path: '/pricing',
  component: () =>
    import( /* webpackChunkName:"pricing"*/ './../views/Pricing.vue'),
  name: 'pricing',
},
{
  path: '/resume/checkout/:uuid',
  component: () =>
    import(
      /* webpackChunkName:"resume-checkout"*/
      './../views/ResumeCheckout.vue'
    ),
  name: 'resume-checkout',
},
{
  path: '/confirmation/:confirmationToken',
  component: () =>
    import(
      /* webpackChunkName:"email-confirmed"*/
      './../views/EmailConfirmed.vue'
    ),
  name: 'email-confirmed',
},
{
  path: '/confirmation-email-sender',
  component: () =>
    import(
      /* webpackChunkName:"email-sender-confirmed"*/
      './../views/EmailConfirmed.vue'
    ),
  name: 'email-sender-confirmed',
},
{
  path: '/display/:token',
  component: () =>
    import( /* webpackChunkName:"display"*/ './../views/AdDisplay.vue'),
  name: 'display',
},
{
  path: '/login',
  component: () =>
    import( /* webpackChunkName:"login"*/ './../views/Login.vue'),
  name: 'login',
  /*  meta: {
    redirectToSearchIfLoggedIn: true
  } */
},
{
  path: '/forgot',
  component: () =>
    import(
      /* webpackChunkName:"forgot-password-request"*/
      './../views/ForgotPasswordRequest.vue'
    ),
  name: 'forgot',
},
{
  path: '/reset-password/:token',
  component: () =>
    import(
      /* webpackChunkName:"reset-password"*/
      './../views/ResetPassword.vue'
    ),

  name: 'reset-password',
},
{
  path: '/terms',
  component: () =>
    import( /* webpackChunkName:"terms"*/ './../views/Terms.vue'),
  name: 'terms',
},
{
  path: '/privacy',
  component: () =>
    import( /* webpackChunkName:"privacy"*/ './../views/Privacy.vue'),
  name: 'privacy',
},
{
  path: '/affiliation',
  component: () =>
    import( /* webpackChunkName:"affiliation"*/ './../views/Affiliation.vue'),
  name: 'affiliation',
},
{
  path: '/search',
  component: () =>
    import( /* webpackChunkName:"search"*/ './../views/SearchContent.vue'),
  name: 'search',
  meta: {
    requiresAuth: true,
  },
  async beforeEnter(to, from, next) {
    if (from.path === to.path) {
      return next(false)
    }
    await store.dispatch('fetchStripeAndSubscription').then(() => {
      if (!store.getters.isSubscriptionValid) {
        next({
          name: 'settings',
        })
      } else {
        next()
      }
    })
  },
  async beforeLeave(to, from, next) {
    await store.dispatch('fetchStripeAndSubscription').then(() => {
      if (!store.getters.isSubscriptionValid) {
        next({
          name: 'settings',
        })
      } else {
        next()
      }
    })
  },
},
{
  path: '/api',
  component: () => import( /* webpackChunkName:"api"*/ './../views/API.vue'),
  name: 'api',
},
{
  path: '/settings',
  component: () =>
    import( /* webpackChunkName:"settings"*/ './../views/Settings.vue'),
  name: 'settings',
  meta: {
    requiresAuth: true,
    requiresActiveSubscription: false,
  },
  async beforeEnter(to, from, next) {
    await store.dispatch('fetchStripeAndSubscription').then(() => {
      next()
    })
  },
},
{
  path: '/blog',
  component: () =>
    import( /* webpackChunkName:"blog-home"*/ './../views/BlogHome.vue'),
  name: 'blog-home',
},
{
  path: '/blog/:slug',
  redirect: '/actualites',
},
{
  path: '/:category',  // Update the path to include /blog/
  name: 'category',
  props: true,
  component: () =>
    import( /* webpackChunkName:"blog-home"*/ './../views/CategoryPage.vue')
},
{
  path: '/:category/:slug',  // Update the path to include /blog/
  name: 'blog-post',
  props: true,
  meta: {
    key: route => route.fullPath
  },
  component: () =>
    import( /* webpackChunkName:"blog-home"*/ './../views/BlogPost.vue')
},


{
  path: '*',
  component: () => import( /* webpackChunkName:"404"*/ './../views/404.vue'),
  name: '404',
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Only scroll if it's a different path, not just a filter toggle
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 }
      }
    }
    // Return false or undefined to maintain current scroll position
    return false;
  }
})


router.afterEach((to, from) => {
  store.commit('UPDATE_LOADING', false)
  store.commit('CLOSE_NAV')
})

router.beforeEach((to, from, next) => {

  store.commit('UPDATE_LOADING', true)
  if (to.matched.some((record) => record.meta.redirectToSearchIfLoggedIn)) {
    if (store.getters.isLoggedIn) {
      next({
        path: '/search',
      })
    } else {
      next()
    }
  } else {
    next()
  }
})



export default router
