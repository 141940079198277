import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLazyload from "vue-lazyload";
import VueMeta from "vue-meta";
import "./plugins/bootstrap-vue";
import Vue2Filters from "vue2-filters";
import Notifications from "vue-notification";
import VTimelinePro from 'vue-timeline-pro';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCalendar, faClock } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faCalendar, faClock)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(Chartkick.use(Chart))

Vue.use(VTimelinePro);

const moment = require("moment");
require("moment/locale/fr");

Vue.use(require("vue-moment"), {
  moment,
});
Vue.prototype.moment = moment
var Vue2FiltersConfig = {
  number: {
    format: "0,0",
    thousandsSeparator: " ",
    decimalSeparator: ".",
  },
};



Vue.use(VueLazyload, {
  preLoad: 1.3,
 /*  error: require("./assets/icons/spinner.svg"),
  loading: require("./assets/icons/spinner.svg"), */
  attempt: 1,
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(Vue2Filters, Vue2FiltersConfig);
Vue.use(Notifications);

Vue.config.productionTip = false;
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

new Vue({
  router,
  store,
  mixins: [Vue2Filters.mixin],
  render: (h) => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
